/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "../node_modules/@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "../node_modules/@ionic/angular/css/normalize.css";
@import "../node_modules/@ionic/angular/css/structure.css";
@import "../node_modules/@ionic/angular/css/typography.css";
@import '../node_modules/@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "../node_modules/@ionic/angular/css/padding.css";
@import "../node_modules/@ionic/angular/css/float-elements.css";
@import "../node_modules/@ionic/angular/css/text-alignment.css";
@import "../node_modules/@ionic/angular/css/text-transformation.css";
@import "../node_modules/@ionic/angular/css/flex-utils.css";



ion-modal.auto-height {
  --height: auto;
}
ion-modal.auto-height .ion-page {

  position: relative;
  display: block;
  contain: content;
}
ion-modal.auto-height .ion-page .inner-content {
  overflow: auto;
}
.dynapuff {
  font-family: "DynaPuff", system-ui;
  font-optical-sizing: auto;
  font-weight:400;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}
ion-modal.bottom-modal {
 
  bottom: 0;
  position: absolute;
  height: 70% ;
  top:  auto;

 
  --backdrop-opacity:0
}
@media screen and (min-width: 1023px) {
  ion-modal.bottom-modal {
    // --height:auto;
    

  }
}
.border-black {
  border: 1px solid #263238
}
.full-screen-modal {
  --width: 100%;
  --height: 100%;
}
.modal-color {
  --background: #263238; /* using Ionic CSS variables */

}
ion-modal.full-height-modal {
  --height: 100vh;
  --width: 50%;
  --min-width: 50%;
  --max-width: 50%;
}
ion-modal.full-height-modal .modal-wrapper {
  position: absolute;
  bottom:0px;
  --height: 100vh;
  --width: 50%;
  --min-width: 50%;
  --max-width: 50%;
}
.main{
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}
.flex{
  flex:1
}
.disabled {
  color: lightgray
}

.img-disabled {
  filter: grayscale(100%) opacity(0.3)
}
.choose-points-item-modal {
  --background: #263238; /* using Ionic CSS variables */
 --width: 90vw;
  --height: 90vh;
}
.bw {
  filter: grayscale(100%)
}

.huerotate {
  filter: saturate(10) hue-rotate(220deg) brightness(100%) contrast(120%);
}
ion-content{
  height: 100%;
}
.bg-primary {
  background-color: var(--ion-color-primary);
}

.col {
  display: flex;
  flex-direction: column;
}
.my-col {
  display: flex;
  flex-direction: column;
}

.col-full-height {
  height: 100%;
  display: flex;
  flex-direction: column;
}


.col-toolbar-height {
  height: calc(100% - 44px);
  display: flex;
  flex-direction: column;
}

  .row {
    display: flex;
    flex-direction: row;
  }
  .my-row {
    display: flex;
    flex-direction: row;
  }
  .border-red {
    border:1px solid red
  }
  .border-green {
    border:1px solid green
  }
  .border-blue {
    border:1px solid blue
  }

.row-wrap {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
}
.my-row-wrap {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
}

.full-height {
  --height:100vh
}

.scroll {
  height: 100%;
}

.center-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.flex-row {
  display: flex;
  flex-direction: column;
}

.flex-col {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
}
.dark-backdrop {
  ion-backdrop {
    --backdrop-opacity: 0.6;

    //  todo add color here
  }
  .no-scroll {
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
  }
  /* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}
/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}
html, body {
  overscroll-behavior: none;
  padding-bottom: env(safe-area-inset-bottom);
}
}
.center-center{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
body {
  padding-top: env(safe-area-inset-top);
  padding-top: constant(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
}
.accent {
  color: var(--ion-color-warning);
}
ion-header {
  padding-top: env(safe-area-inset-top);
}
.round-button{
border-radius: 50%;
display:flex;
align-items:center;
justify-content:center;
padding:5px;
}
ion-modal.fullscreen {
  --width: 100%;
  --height: 100%;
  --border-radius: 0;
}

.flicker-1 {
	-webkit-animation: flicker-1 2s linear infinite both;
	        animation: flicker-1 2s linear infinite both;
}
  .vibrate-1 {
	-webkit-animation: vibrate-1 0.3s linear infinite both;
	        animation: vibrate-1 0.3s linear infinite both;
}
/* ----------------------------------------------
 * Generated by Animista on 2024-10-29 16:45:15
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flicker-1
 * ----------------------------------------
 */
 @-webkit-keyframes flicker-1 {
    0%,
    100% {
      opacity: 1;
    }
    41.99% {
      opacity: 1;
    }
    42% {
      opacity: 0;
    }
    43% {
      opacity: 0;
    }
    43.01% {
      opacity: 1;
    }
    47.99% {
      opacity: 1;
    }
    48% {
      opacity: 0;
    }
    49% {
      opacity: 0;
    }
    49.01% {
      opacity: 1;
    }
  }
  @keyframes flicker-1 {
    0%,
    100% {
      opacity: 1;
    }
    41.99% {
      opacity: 1;
    }
    42% {
      opacity: 0;
    }
    43% {
      opacity: 0;
    }
    43.01% {
      opacity: 1;
    }
    47.99% {
      opacity: 1;
    }
    48% {
      opacity: 0;
    }
    49% {
      opacity: 0;
    }
    49.01% {
      opacity: 1;
    }
  }
  
/* ----------------------------------------------
 * Generated by Animista on 2024-10-29 16:37:31
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation vibrate-1
 * ----------------------------------------
 */
 @-webkit-keyframes vibrate-1 {
    0% {
      -webkit-transform: translate(0);
              transform: translate(0);
    }
    20% {
      -webkit-transform: translate(-2px, 2px);
              transform: translate(-2px, 2px);
    }
    40% {
      -webkit-transform: translate(-2px, -2px);
              transform: translate(-2px, -2px);
    }
    60% {
      -webkit-transform: translate(2px, 2px);
              transform: translate(2px, 2px);
    }
    80% {
      -webkit-transform: translate(2px, -2px);
              transform: translate(2px, -2px);
    }
    100% {
      -webkit-transform: translate(0);
              transform: translate(0);
    }
  }
  @keyframes vibrate-1 {
    0% {
      -webkit-transform: translate(0);
              transform: translate(0);
    }
    20% {
      -webkit-transform: translate(-2px, 2px);
              transform: translate(-2px, 2px);
    }
    40% {
      -webkit-transform: translate(-2px, -2px);
              transform: translate(-2px, -2px);
    }
    60% {
      -webkit-transform: translate(2px, 2px);
              transform: translate(2px, 2px);
    }
    80% {
      -webkit-transform: translate(2px, -2px);
              transform: translate(2px, -2px);
    }
    100% {
      -webkit-transform: translate(0);
              transform: translate(0);
    }
  }
  